<template>
  <div>
    <!-- Popup Button (in the corner) -->
    <button class="popup-btn" @click="togglePopup">Save 10%</button>

    <!-- Popup -->
    <div v-if="isVisible" class="popup-overlay">
      <div class="popup">
        <button class="close-button" @click="togglePopup">×</button>
        <div class="popup-content">
          <!-- Left Column -->
          <div class="popup-left">
            <!-- Left Image -->
            <div class="popup-left-image">
              <img src="img/home/pop-up-02.svg" alt="Left Image" />
            </div>

            <!-- Text and Mailchimp Form -->
            <div class="popup-text">
              <p>
                Sign up for cool and exciting emails from the Ode fam and save
                10% on your first stay
              </p>
              <div id="mc_embed_shell">
                <link
                  href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                  rel="stylesheet"
                  type="text/css"
                />
                <div id="mc_embed_signup">
                  <form
                    action="https://odetoronto.us20.list-manage.com/subscribe/post?u=c2dcb1a9b9f587e3b1adc84f2&amp;id=f63c69dbe5&amp;f_id=000d41eef0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    class="validate"
                    target="_blank"
                  >
                    <div id="mc_embed_signup_scroll">
                      <div class="mc-field-group">
                        <label for="mce-FNAME" class="sr-only">Name</label>
                        <input
                          type="text"
                          name="FNAME"
                          class="text"
                          placeholder="Name"
                          id="mce-FNAME"
                        />
                      </div>
                      <div class="mc-field-group">
                        <label for="mce-EMAIL" class="sr-only"
                          >Email Address <span class="asterisk">*</span></label
                        >
                        <input
                          type="email"
                          name="EMAIL"
                          class="required email"
                          placeholder="Email"
                          id="mce-EMAIL"
                          required
                        />
                      </div>
                      <div
                        aria-hidden="true"
                        style="position: absolute; left: -5000px"
                      >
                        <input
                          type="text"
                          name="b_c2dcb1a9b9f587e3b1adc84f2_f63c69dbe5"
                          tabindex="-1"
                        />
                      </div>
                      <div class="clear">
                        <input
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          class="button"
                          value="SUBMIT"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="fine-print">
                <p>
                  Don't worry, you can unsubscribe at any time. All emails sent
                  by Ode to Hospitality Corp., 1417 Dundas St W, Toronto, ON.
                </p>
              </div>
            </div>
          </div>

          <!-- Right Column -->
          <div class="popup-right">
            <div class="popup-image">
              <img src="img/home/DSCF1800.jpg" alt="Right Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    const popupClosed = localStorage.getItem("popupClosed");
    if (!popupClosed) {
      setTimeout(() => {
        this.isVisible = true;
      }, 5000);
    }
  },
  methods: {
    togglePopup() {
      this.isVisible = !this.isVisible;
      if (!this.isVisible) {
        localStorage.setItem("popupClosed", "true");
      }
    },
  },
};
</script>

<style scoped>
/* Overlay styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Founders Grotesk Regular";
}

/* Popup container */
.popup-content {
  display: flex;
}
.popup {
  position: relative;
  display: flex;
  background: white;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
}

/* Left column styling */
.popup-left {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.popup-left-image img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Text and form styling */
#mc_embed_signup .button,
#mc_embed_signup input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid black;
  font-family: "Founders Grotesk Regular";
}

#mc_embed_signup .button {
  margin: 0px;
  height: fit-content;
}

#mc_embed_signup .mc-field-group {
  width: 100%;
}

.fine-print {
  font-size: 10px;
  padding-top: 30px;
  font-family: "Founders Grotesk Regular";
}
.popup-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  font-size: 17px;
  line-height: 1.2;
  padding: 0px 30px;
}

p {
  margin: 0;
}

input {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid black;
  font-size: 13.5px;
}

.submit-button {
  background: black;
  color: white;
  margin-top: 5px;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

/* Right column styling */
.popup-right {
  position: relative;
  flex: 1;
}

.popup-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: -10px;
  right: 5px;
  background: none;
  border: none;
  font-size: 33px;
  color: white;
  cursor: pointer;
  z-index: 10;
}

/* Popup Button */
.popup-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: black;
  color: #feff66;
  border: 1px solid #feff66;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1001;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .popup {
    flex-direction: column;
    width: 60%;
  }

  .popup-right {
    display: none;
  }

  .popup-left {
    width: 100%;
  }

  .popup-text {
    font-size: 14px;
    line-height: 1;
    padding: 0px;
  }

  .fine-print {
    font-size: 10px;
    padding-top: 5px;
  }

  .close-button {
    top: -10px;
    right: -28px;
    color: black;
  }
}

@media screen and (max-width: 667px) {
  .popup {
    width: 80%;
  }
}
</style>
