<template>
  <div id="home_wrapper" class="scroller">
    <div id="home" class="position-relative bg-yellow">
      <div class="header">
        <h1>Ode Toronto - Home</h1>
      </div>

      <Hero />
      <Story id="story" class="live-life-container" />
      <Rooms id="rooms" />
      <Roof id="roof" />
      <FullImg />
      <Neighbourhood id="neighbourhood" />
      <Art id="art" />
      <FAQ id="faq" />
      <Social id="social" />
      <AppFooter />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from "@/components/Home/Hero";
import Story from "@/components/Home/Story";
import Rooms from "@/components/Home/Rooms";
import Roof from "@/components/Home/Roof";
import Art from "@/components/Home/Art";
import FullImg from "@/components/Home/FullImg";
import Neighbourhood from "@/components/Home/Neighbourhood";
import FAQ from "@/components/Home/FAQ";
import Social from "@/components/Home/Social";
import AppFooter from "@/components/Home/AppFooter";

export default {
  data() {
    return {
      scrollSmoother_home: null,
    };
  },
  components: {
    Hero,
    Story,
    Rooms,
    Roof,
    Art,
    FullImg,
    Neighbourhood,
    FAQ,
    Social,
    AppFooter,
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    ScrollTrigger.refresh();

    setTimeout(() => {
      this.initscrollSmoother_homePage();
      this.initImgElReveal_homePage();
    }, 500);
  },
  methods: {
    initscrollSmoother_homePage() {
      this.scrollSmoother_home = ScrollSmoother.create({
        wrapper: "#home_wrapper",
        content: "#home",
        smooth: 3,
        effects: true,
      });
      console.log("ScrollSmoother initialized");
    },
    initImgElReveal_homePage() {
      const fadeInUpEl = document.querySelectorAll(".fade-in-up-element");
      fadeInUpEl.forEach((container) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: container,
              start: "center bottom",
              stagger: 0.2,
            },
          })
          .set(container, { autoAlpha: 1, zIndex: 1 })
          .from(container, 1, {
            opacity: 0,
            y: 100,
            ease: "power2.out",
          });
      });
      console.log("Image reveal initialized");
    },
  },
  beforeDestroy() {
    console.log("Cleaning up ScrollTriggers");
    ScrollTrigger.getAll().forEach((t) => t.kill());
  },
};
</script>
